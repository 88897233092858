@import 'utils/styles/_mixins';

.title {
  margin-bottom: $s-lg;

  text-align: center;
}

.organism__tabs-wrapper-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $s-lg;

  @include gt-phone {
    gap: $s-xxl;
  }

  .tabs-wrapper {
    @include gt-tablet {
      display: flex;
      gap: $s-xxl;
    }
  }

  .hidden {
    display: none;
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    gap: $s-zs;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .modal-container_title {
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
    font-weight: 500;
  }

  .modal-container_text {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .modal-container_button {
    color: $c-primary;
    &:hover {
      color: $c-primary-dark;
    }
  }
}
