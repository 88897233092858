@import 'utils/styles/colors';
@import 'utils/styles/mixins';
@import 'utils/styles/_forms';

/* VARIABLES
========================================================================== */
$field-padding: 1.2rem;

/* INPUT FIELD
========================================================================== */
.atom__form-input-container {
  .field-input {
    padding: $field-padding;

    height: $s-xl;

    background-color: $c-base-white;

    cursor: text;
  }
}
