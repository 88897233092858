@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* FIELDS CONTAINER
========================================================================== */
.ff-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: $s-lg;
}

/* FORM FIELDS
========================================================================== */
.ff-box,
.ff-box-clean {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 $s-s;
  margin: 0;
  border: $c-gray-3 solid 0.1rem;
  outline: none;
  background: transparent;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;

  &:focus,
  &:active,
  &:focus-visible {
    outline: none;
    border: $c-gray-6 solid 0.1rem;
  }

  &:disabled {
    outline: none;
    border: none;
    background-color: $c-gray-2;
    cursor: not-allowed;
  }
}

.ff-box-clean {
  margin: 0;
}

.absent {
  position: absolute;
  visibility: hidden;
}

.ff-box-error {
  border: $c-state-danger-dark solid 0.1rem;
}

/* ERROR MESSAGE
========================================================================== */
.ff-error-message,
.ff-error-message-checkbox {
  position: relative;
  left: 0;
  color: $c-state-danger-dark;
  font-weight: 400;
  font-size: $text-size-tiny;
  line-height: $text-size-medium;
}

/* LABEL
========================================================================== */
.ff-label {
  color: $c-base-text;
  font-size: $text-size-tiny;
  line-height: $text-size-default;
  margin-bottom: $s-s;
}

.ff-disabled {
  color: $c-gray-4;
}
