@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';
@import 'utils/styles/_sizes';

/* VARIABLES
========================================================================== */
$card-car-container-width: 31.2rem;
$card-car-container-height: 30.6rem;
$card-car-details-height: 21.8rem;
$card-car-content-height: 4rem;
$card-car-content-width: 13.2rem;
$content-car-width: 30rem;
$image-car-width: 27rem;
$card-car-height: 14.6rem;
$card-car-price-height: 14.4rem;
$card-car-container-price-detail: 8rem;

.molecule__tab-car-container {
  position: relative;

  @include gt-phone {
    padding-bottom: $s-xxl;
  }

  .card-car-container {
    display: flex;
    padding: $s-s;
    width: $card-car-container-width;
    height: $card-car-container-height;
    background-color: $c-base-white;
    border-radius: $s-zs;
    &:hover {
      box-shadow: 0px $s-zzz $s-zzz 0px rgba(0, 0, 0, 0.25);
      border: 1px solid $c-gray-1;
    }
  }

  &[data-active='price'],
  &[data-active='detail'] {
    .card-car-container {
      box-shadow: 0px $s-zzz $s-zzz 0px rgba(0, 0, 0, 0.25);
      border: 1px solid $c-gray-1;
    }
  }

  /* CARD CAR
  ========================================================================== */
  .card-car {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $s-s;
  }

  /* CARD IMAGE
  ============== */
  .car-image {
    width: $image-car-width;
    height: $card-car-height;
  }

  /* CARD CONTENT
  ================ */
  .car-label {
    text-transform: uppercase;
    font-weight: 500;
    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;
  }

  .car-container-price-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $s-s;
    height: $card-car-container-price-detail;
  }

  .car-content-price {
    display: flex;
    flex-direction: row;
    font-size: $text-size-xtiny;
    line-height: $text-line-height-medium;
    max-width: $content-car-width;
    width: 100%;
    justify-content: center;
  }

  .car-content-divider {
    border-right: 1px solid $c-gray-6;
  }

  .car-content-info-right,
  .car-content-info-left {
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    color: $c-gray-6;
  }

  .car-content-info-left {
    padding-right: $s-zs;
  }

  .car-content-info-right {
    padding-left: $s-zs;
  }

  .car-content-info-price {
    color: $c-base-black;
  }

  .car-content-info-right-package {
    text-align: center;
    line-height: $text-line-height-default;
    color: $c-gray-6;
  }

  .centered {
    display: flex;
    justify-content: center;
  }

  /* CARD BUTTONS DETAILS
  ======================== */
  .car-content-details {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: $s-lg;
  }

  /* CARD CAR HEADER
  ========================================================================== */
  .card-car-header {
    display: flex;
    justify-content: space-between;
    height: $card-car-content-height;
  }

  .card-car-header-content {
    display: flex;
    flex-direction: column;
    gap: $s-zs;

    .header-title {
      font-size: $text-size-tiny;
      line-height: $text-line-height-default;
      font-weight: 500;
    }

    .header-name {
      font-size: $text-size-xtiny;
      line-height: $text-line-height-default;
    }
  }

  /* CARD CAR DETAILS AND CARD CAR PRICE
  ========================================================================== */
  .card-car-detail,
  .card-car-price {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: $s-s;
  }

  .card-car-detail-content,
  .card-car-price-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: $card-car-details-height;
  }

  .card-car-detail-content {
    gap: $s-s;
  }

  .card-car-detail-content-item {
    display: flex;
    flex-direction: row;
    height: $card-car-content-height;
    width: $card-car-content-width;
    padding: 0px $s-zs;
    gap: $s-zs;
    border-radius: $s-zzz;
    background-color: $c-gray-1;
    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;
    align-items: center;
  }

  .card-car-detail-content-item-image {
    width: $s-s;
  }

  .card-car-detail-content-item-description {
    max-width: $card-car-content-width;
    width: 100%;
  }

  .card-car-detail-content-item-description-text {
    width: 100%;
    text-align: center;
  }

  /* CARD  PRICE
  ======================================== */
  .card-car-price-content {
    height: $card-car-price-height;
    color: $c-gray-6;
  }

  .card-car-price-content-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: $text-size-tiny;
    line-height: $text-line-height-small;
    padding: $s-zs;
  }

  .card-car-price-content-item-price {
    color: $c-base-text;
  }

  .card-car-price-content-item:nth-child(even) {
    background-color: $c-base-white;
  }

  .card-car-price-content-item:nth-child(odd) {
    background-color: $c-gray-1;
  }

  .card-car-price-info {
    display: flex;
    flex-direction: column;
    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;
    color: $c-gray-6;
  }
  .card-car-price-info-price {
    color: $c-base-text;
  }

  .card-car-button-close {
    cursor: pointer;
  }

  // ANIMATIONS
  .card-car,
  .card-car-detail,
  .card-car-price {
    opacity: 0;
    animation: fadeIn 1s forwards;
    backface-visibility: hidden;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}
