/* stylelint-disable */
@import '../../utils/styles/_colors';
@import '../../utils/styles/_mixins';

/* VARIABLES
======================================================================== */
$dots-list-height: 2rem;

/* SLIDER
========================================================================== */
.slider-wrapper {
  position: relative;
}

.action-banner-wrapper {
  position: relative;

  [class^='slick-slide'] {
    overflow: hidden;
  }

  /* DOTS
  ========================================================================== */
  [class^='slick-dots'] {
    position: absolute;
    bottom: $s-md;
    left: 50% !important;
    height: $dots-list-height;

    transform: translate(-50%, 0) !important;

    @include gt-tablet {
      bottom: $s-xxl;
      justify-content: start;
      width: $grid-width-desktop;
    }

    li {
      &[class^='slick-active'] {
        button {
          background-color: $c-base-white !important;
        }
      }
    }

    button {
      background-color: $c-base-white !important;
    }
  }
}
