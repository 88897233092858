@import 'utils/styles/colors';
@import 'utils/styles/mixins';
@import 'utils/styles/_forms';

/* VARIABLES
========================================================================== */
$field-padding: 1.2rem;

/* INPUT FIELD
========================================================================== */
.atom__form-select-container {
  .field-select-wrapper {
    position: relative;
    align-items: center;
  }

  .field-select {
    padding: $field-padding;
    padding-right: 2.8rem;
    min-height: $s-xl;

    background-color: $c-base-white;

    cursor: pointer;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  /* DOWNWARD ARROW
========================================================================== */
  .field-select-wrapper::after {
    content: '';
    position: absolute;
    z-index: $z-index-jump;
    top: 1.8rem;
    right: $s-s;

    height: 0.75rem;
    width: 0.75rem;

    color: transparent;

    pointer-events: none;

    border: none;
    border-right: solid 0.15rem $c-base-text;
    border-bottom: solid 0.15rem $c-base-text;

    transform: rotate(45deg);
  }

  /* SELECT CONTAINER
========================================================================== */
  &[data-is-aside='true'] {
    @include gt-phone {
      display: grid;
      grid-template-columns: repeat(12, 1fr);

      .ff-label {
        position: relative;
        top: $s-zs;
        grid-column: span 5;
      }

      .field-select-wrapper {
        grid-column: span 7;
      }
    }
  }
}
