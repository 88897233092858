
// !!IMPORTANT!! Creator dynamic content start
// primary colors
$c-primary-light                 : var(--primary-light);
$c-primary                       : var(--primary);
$c-primary-dark                  : var(--primary-dark);
$c-primary-intense               : var(--primary-intense);
// secondary colors
$c-secondary                     : var(--secondary);
//link colors
$c-link-default                  : var(--link-default);
$c-link-hover                    : var(--link-hover);
$c-link-disabled                 : var(--link-disabled);
$c-link-default-dark             : var(--link-default-dark);
$c-link-hover-dark               : var(--link-hover-dark);
$c-link-disabled-dark            : var(--link-disabled-dark);
// state colors
$c-state-success                 : var(--state-success);
$c-state-success-dark            : var(--state-success-dark);
$c-state-warn                    : var(--state-warn);
$c-state-warn-dark               : var(--state-warn-dark);
$c-state-danger                  : var(--state-danger);
$c-state-danger-dark             : var(--state-danger-dark);
// base colors
$c-base-text                     : var(--base-text);
$c-base-link                     : var(--base-link);
$c-base-bg                       : var(--base-bg);
$c-base-lightest                 : var(--base-lightest);
$c-base-lighter                  : var(--base-lighter);
$c-base-light                    : var(--base-light);
$c-base                          : var(--base);
$c-base-dark                     : var(--base-dark);
$c-base-darker                   : var(--base-darker);
$c-base-darkest                  : var(--base-darkest);
$c-base-black                    : var(--base-black);
$c-base-white                    : var(--base-white);
// gray colors
$c-gray-1                        : var(--gray-1);
$c-gray-2                        : var(--gray-2);
$c-gray-3                        : var(--gray-3);
$c-gray-4                        : var(--gray-4);
$c-gray-5                        : var(--gray-5);
$c-gray-6                        : var(--gray-6);
$c-gray-7                        : var(--gray-7);
// blue colors
$c-blue-1                        : var(--blue-1);
$c-blue-2                        : var(--blue-2);
$c-blue-3                        : var(--blue-3);
$c-blue-4                        : var(--blue-4);

/*
 * Combinations of colors
 */

// headbar combination
@mixin c-headbar {
  color           : $c-primary;
  background-color: $c-base-text;
}

// highlight combination
@mixin c-highlight {
  color           : $c-secondary;
  background-color: $c-base-text;
}

// success combination
@mixin c-success {
  color           : $c-state-success;
  background-color: $c-base-text;
}

// danger combination
@mixin c-danger {
  color           : $c-state-danger;
  background-color: $c-base-lightest;
}

// warn combination
@mixin c-warn {
  color           : $c-state-warn;
  background-color: $c-base-text;
}

// info combination
@mixin c-info {
  color           : $c-state-info;
  background-color: $c-base-text;
}
// !!IMPORTANT!! End of Creator dynamic content
