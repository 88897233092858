@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.find-dealers-container {
  position: relative;
  display: grid;
  gap: $s-xl;

  @include gt-tablet {
    gap: $s-xxl;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    gap: $s-lg;
    width: 100%;
  }

  .form-container-header {
    display: flex;
    flex-direction: column;
    gap: $s-s;
    margin-block-end: $s-s;

    @include gt-phone {
      margin-block-end: $s-lg;
    }
  }

  .form-header-title {
    font-weight: 400;
  }

  .form-header-description {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
    color: $c-gray-4;
  }

  /* FORM FOR FILTERS
  ========================================================================== */
  .filter-form {
    display: flex;
    flex-direction: column;
    gap: $s-md;

    @include gt-tablet {
      flex-direction: row;
      gap: $s-lg;
    }
  }

  .select-input {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 0;
  }

  .select-input {
    option {
      color: $c-base-black;
    }
  }

  .placeholder-selected,
  .loading-indicator {
    color: $c-gray-4;
  }

  .loading-indicator {
    font-size: $text-size-xtiny;
    &::after {
      content: '.';
      animation: loading 1s infinite linear;
    }
  }

  .toast {
    position: fixed;
    bottom: $s-md;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 0, 0, 0.8);
    color: #fff;
    padding: $s-md;
    border-radius: $s-zz;
    animation:
      slideIn 0.5s ease-in-out forwards,
      fadeOut 2s ease-in forwards 5s;
    opacity: 0;
    z-index: 9999;
  }

  @keyframes loading {
    0% {
      content: '.';
    }
    50% {
      content: '..';
    }
    100% {
      content: '...';
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateX(-50%) translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(-50%) translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
