@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';
@import 'utils/styles/_sizes';


.contact-form-container {
  position: relative;

  .fieldset {
    display: grid;
    border: none;
    gap: $s-xl;

    @include gt-phone {
      gap: $s-xxl;
    }
  }

  .fieldset-form {
    display: grid;
    border: none;
    gap: $s-xl;


    @include gt-phone {
      gap: $s-xxl;
    }
  }

  .fieldset-input {
    border: none;
    display: grid;
    gap: $s-lg;
    grid-template-columns: repeat(1, 100%);

    @include gt-phone {
      grid-template-columns: repeat(3, 100%);
    }
  }

  .subtitle {
    font-size: $text-size-small;
    font-weight: 400;
    line-height: $text-line-height-medium;
    color: $c-primary;
  }

  .container-two {
    @include gt-phone {
      grid-column: 7/13;
    }
  }

  .submit_button {
    width: 100%;
  }

  .form-field-service {
    margin-bottom: 0;
  }

  .form-field {
    margin-bottom: 0;

    ::placeholder{
      color: $c-gray-4;
    }

    > input {
      margin-bottom: 0;
    }
    div > select {
      margin-bottom: 0;
      line-height: $text-line-height-small;
      border-color: $c-gray-3;
    }
    > textarea {
      margin-bottom: 0;
      resize: vertical;
    }
  }

    .agreement {
      margin: $s-lg 0;

      @media (max-width: 1250px) {
        margin: $s-s 0;
      }

      > label {
        margin-bottom: 0;
        color: $c-base-text;
        line-height: $text-line-height-medium;
        font-size: $text-size-default;
      }
    }

  .placeholder-selected {
    color: $c-gray-4;
    option {
      color: $c-base-text
    }
  }
}
