@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';
@import 'utils/styles/sizes';

.atom__tab-container {
  position: relative;

  margin-bottom: $s-zzz;
  padding: $s-zzz;

  font-size: $s-s;

  border: none;

  transition: opacity ease $basic-transition;

  &:hover, &:focus {
    text-decoration: none;

    opacity: .7;
    transition: opacity ease $basic-transition;
  }

}

.selected {
  border-bottom: $s-zzz solid $c-primary;
}
