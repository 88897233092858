@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$info-button-width: 14.7rem;
$button-width: 17.8rem;
$service-button-min-width: 23.3rem;
$max-height-container-dealer: 81.3rem;
$size-logo-service: 10rem;

/* INFO DEALER CONTAINER
========================================================================== */
.molecule__info-dealer-container {
  background: $c-base-white;
  color: $c-base-text;

  @include gt-phone {
    max-height: $max-height-container-dealer;
    overflow-y: auto;
  }

  /* INFO DEALER
  ========================================================================== */
  .info-dealer {
    display: flex;
    flex-direction: column;
    gap: $s-md;
    width: 100%;
  }

  .info-dealer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-favorite {
      margin-right: 1rem;
      border: 0;
      color: $c-primary;
    }
  }

  .info-dealer-container {
    display: flex;
    flex-direction: column;

    width: 100%;
    gap: $s-md;

    @include gt-phone {
      position: relative;

      flex-direction: row;
      justify-content: space-between;
    }
  }

  /* ADDRESS
  ========================================================================== */
  .info-dealer-container__address {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $s-zs;
    width: 100%;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
  }

  .email-address-container {
    display: flex;
    line-height: normal;
  }

  .info-dealer-container__address__email {
    overflow: hidden;

    text-overflow: ellipsis;
    transition: opacity ease $basic-transition;

    &:hover,
    &:focus {
      opacity: 0.6;
      transition: opacity ease $basic-transition;
    }
  }

  .info-dealer-container__address__website {
    display: flex;
    align-items: center;
    margin: $s-s 0;
    gap: $s-zs;

    text-decoration: underline;
    transition: opacity ease $basic-transition;

    svg {
      width: $s-s;
      height: $s-s;
    }

    &:hover,
    &:focus {
      opacity: 0.6;
      transition: opacity ease $basic-transition;
    }
  }

  .info-dealer-container__address__phones {
    display: grid;
    line-height: $text-line-height-medium;
  }

  .info-dealer-container__address__phones__title {
    font-size: $text-size-default;
    line-height: $text-line-height-medium;
    font-weight: 500;
  }

  .info-dealer-container__address__cell-whatsap {
    display: flex;
    align-items: center;
    gap: $s-zs;

    svg {
      width: $s-md;
      height: $s-md;
      transition: opacity ease $basic-transition;

      &:hover,
      &:focus {
        opacity: 0.8;
        transition: opacity ease $basic-transition;
      }
    }
  }

  /* BUTTON
  ========================================================================== */
  .info-dealer-container__button {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .button {
    width: 100%;
    font-weight: 500;

    @include gt-phone {
      width: $info-button-width;
    }
  }

  /* SERVICES
  ========================================================================== */
  .service-container {
    display: flex;
    gap: $s-md;
    flex-direction: row;
  }

  .service {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $s-zs;
  }

  .service-logo {
    width: $size-logo-service;
    height: $size-logo-service;
  }

  .service-link,
  .service-span {
    font-size: $text-size-xtiny;
    line-height: $text-line-height-default;
    color: $c-primary;
  }

  .service-link {
    text-decoration: underline;
    &:hover,
    &:focus {
      color: $c-primary-dark;
    }
  }

  /* LINKS MAPS
  ========================================================================== */

  .maps-links-container {
    display: flex;
    flex-direction: column;
    gap: $s-zs;
  }

  .maps-link {
    display: flex;
    align-items: center;
    gap: $s-zs;
    line-height: $text-line-height-medium;
    color: $c-primary;

    &:hover,
    &:focus {
      color: $c-primary-dark;
    }
  }

  .maps-links-icon {
    width: 24px;
  }
}
