@import 'utils/styles/colors';
@import 'utils/styles/mixins';
@import 'utils/styles/_forms';

.atom__form-checkbox-container {

/* CHECKBOX LABEL
========================================================================== */
  .ff-label {
    display: block;
    position: relative;
    padding-left: calc($s-tiny + $s-zs);
    margin-bottom: $s-lg;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input:focus + .checkmark {
      outline-offset: 2px;
      outline: $c-primary-light auto 1px;
    }

    @include gt-phone {
      padding-left: calc($s-s + $s-zs);
    }
  }

  .ff-label.ff-label-clean {
    margin-bottom: 0;
  }

/* HIDE DEFAULT CHECKBOX
========================================================================== */
  .ff-label input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
    cursor: pointer;
  }

/* CUSTOM CHECKBOX CHECKMARK
========================================================================== */
  .checkmark {
    position: absolute;
    margin: auto 0;
    top: 0;
    bottom: 0;
    left: 0;
    height: $s-s;
    width: $s-s;
    border: $c-gray-3 solid 0.1rem;
    background-color: $c-base-white;
  }

  .ff-label:hover input~.checkmark {
    background-color: $c-gray-2;
  }

  .ff-label input:checked~.checkmark {
    background-color: $c-primary;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .ff-label input:checked~.checkmark:after {
    display: block;
  }

  .ff-label .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 8px;
    border: solid $c-base-white;
    border-width: 0 .2rem .2rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

/* CHECKBOX DISABLED
========================================================================== */

  .ff-disabled {
    cursor: not-allowed;

    .checkmark {
      background-color: $c-gray-2;
    }
  }

}
