@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__category-tabs-container {
  position: relative;

  /* TABS
  ========================================================================== */
  .tabs-container {
    position: relative;
    display: flex;
    flex-direction: row nowrap;
    margin: auto;
    //@debug overflow: scroll;

    > button,
    > a {
      white-space: nowrap;
    }
  }

  .centered::before,
  .centered::after {
    // This pushes elements to the center when there is no overflow;
    margin: auto;
    content: '';
  }
}

/* SLIDER
========================================================================== */
.action-slider-wrapper {
  width: 90%;
  width: calc(100% - $s-md);
  display: grid;
  gap: $s-lg;

  @include gt-phone {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .slide-container {
    position: relative;
    z-index: $z-index-ground;
  }

  .slide-container-tab {
    color: $c-base-link;
  }

  [class^='slick-slider'] {
    margin-left: 0;
    margin-right: 0;
    position: relative;
    z-index: $z-index-jump;

    @include gt-phone {
      padding-bottom: $s-s;
    }
  }

  [class^='slick-track'] {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    gap: $s-lg;
  }

  /* DOTS
========================================================================== */
  [class^='slick-dots'] {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    gap: $s-lg;
    transform: translate(-50%, 0);

    @include gt-tablet {
      width: $grid-width-desktop;
    }

    li {
      display: unset;
      margin: 0;
      padding: 0;
      width: unset;
      height: unset;

      &[class^='slick-active'] {
        button {
          width: 4rem;
          background-color: $c-base-black;
          opacity: 1;
        }
      }
    }

    button {
      padding: 0;
      width: 0.8rem;
      height: 0.8rem;
      line-height: 0;
      color: transparent;
      background-color: $c-base-black;
      border: none;
      border-radius: 6px;
      opacity: 0.6;
      transition: width ease $basic-transition;
    }
  }
  .tabs-container::-webkit-scrollbar-thumb:horizontal {
    background-color: $c-primary !important;
    background: $c-primary !important ;
  }

  /* ARROWS
========================================================================== */
  [class^='slick-arrow'] {
    position: absolute;
    border: none;
    color: transparent;
    top: 32%;
    height: 1.5rem;
    width: 1.5rem;
    transform: rotate(45deg);
    z-index: $z-index-jump;
    overflow: hidden;
  }

  [class*='slick-prev'] {
    right: 98%;
    border-left: solid 0.15rem $c-base-black;
    border-bottom: solid 0.15rem $c-base-black;

    @include gt-tablet {
      left: -$s-md;
    }
  }

  [class*='slick-next'] {
    left: 98%;
    border-right: solid 0.15rem $c-base-black;
    border-top: solid 0.15rem $c-base-black;

    @include gt-tablet {
      right: -$s-md;
    }
  }

  [class*='slick-disabled'] {
    cursor: default;
    opacity: 0.2;
  }

  /* BACK LINE
========================================================================== */
  .back-line {
    position: absolute;
    top: 33%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: $s-zs;
    width: calc(100% - $s-xxl * 2);
    background-color: $c-gray-2;
    z-index: 0;
  }
}
