@import 'utils/styles/_forms';

/* VARIABLES
========================================================================== */
$field-padding: 1.2rem;

/* TEXT FIELD
========================================================================== */
.atom__form-text-area-container {
  .field-textarea {
    padding: $field-padding;

    background-color: $c-base-white;

    cursor: text;
  }

  .text-size {
    position: absolute;
    left: 0;
    font-weight: 400;
    font-size: $text-size-tiny;
    line-height: $text-line-height-medium;
  }

  .text-size {
    bottom: $s-md;
    color: $c-gray-4;
  }

  .big-area {
    margin-bottom: $s-xl;
  }
}
