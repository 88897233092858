/* stylelint-disable */
@import '../../utils/styles/_colors';
@import '../../utils/styles/_mixins';

/* VARIABLES
======================================================================== */
$banner-height: 56rem;
$banner-height--medium: 28.8rem;
$info-container-height: 30rem;
$info-container-margin-top: -45vw;
$banner-title-bottom-spacing: 4rem;
$image-mobile-proportion-ratio: 113vw;

/* SLIDE
========================================================================== */
.banner-slider {
  position: relative;

  max-width: 1920px;
  margin: auto;
  overflow: hidden;

  @include gt-tablet {
    display: flex !important;
    height: 34vw;

    min-height: $banner-height;
    max-height: 653px;

    &[data-container-size='medium'],
    &[data-container-size='small'] {
      // defines the height for the small banner on desktop resolutions
      height: $banner-height--medium !important;
      min-height: unset !important;
      max-height: unset !important;
    }
  }

  &[data-container-size='medium'] {
    .info-container {
      &[data-title-only='true'] {
        justify-content: flex-end;

        padding-block: 0 4rem;

        .info-container__description {
          max-width: 100%;
          margin: $s-zs 0 0 0;
        }
      }

      @include gt-phone {
        justify-content: center;

        width: 62rem;
      }
    }

    .info-container__description {
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }

    .info-container__description {
      max-width: 80%;
      @include gt-phone {
        max-width: 60%;
        margin-bottom: 24px;
      }
    }

    @include gt-phone {
      min-height: $banner-height--medium;
    }

    @include phone {
      .poster-wrapper__poster {
        position: absolute;
        top: 0;

        height: 100%;

        object-fit: cover;

        pointer-events: none;
      }

      .content {
        height: initial;

        background-color: transparent;
      }

      .info-container {
        margin: 0;
        padding: 10.6rem 2.4rem 6rem;

        &:before {
          top: 0;

          background: $c-base-black;
          clip-path: initial;
          opacity: 0.35;
        }

        &:after {
          content: none;
        }
      }
    }
  }

  &[data-variant='2'] {
    @include gt-tablet {
      width: $grid-width-desktop;
    }

    .info-container {
      @include gt-tablet {
        padding: 0 0 0 $s-xl;
      }
    }

    .info-container__title {
      font-weight: 500;
      font-size: $text-size-large;
      line-height: $text-line-height-large;
      text-transform: none;
    }
  }
}

.poster-wrapper__poster {
  display: block;

  width: 100%;
  height: $image-mobile-proportion-ratio;

  object-fit: cover;

  @include gt-tablet {
    position: absolute;
    top: 0;
    height: 100%;

    max-height: unset;

    object-fit: cover;

    pointer-events: none;
  }
}

/* CONTENT WRAPPER
========================================================================== */
.content {
  display: flex;
  z-index: $z-index-jump;
  position: relative;
  flex-direction: column;

  background-color: $c-base-black;

  color: $c-base-white;

  [data-title-only='true'] & {
    height: auto;
    background-color: transparent;
  }

  @include gt-tablet {
    align-items: flex-start;

    width: 100%;
    max-width: $grid-width-desktop;
    height: auto;

    margin: 0 auto;
    padding: 0;

    background-color: transparent;
  }
}

/* INFO
========================================================================== */
.info-container {
  display: flex;
  z-index: $z-index-ground;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  height: $info-container-height;

  margin-top: $info-container-margin-top;
  margin-bottom: $s-s;
  padding: 0 $grid-padding $s-xl;

  @include gt-phone {
    justify-content: flex-start;
  }

  &[data-remove-mask='true'] {
    padding: 0;

    .info-container__title {
      font-weight: 500;
      font-size: $text-size-large;
      line-height: $text-line-height-large;
      text-transform: none;
    }
  }

  [data-title-only='true'] & {
    .info-container__title {
      @include gt-phone {
        max-width: 70rem;
        margin-bottom: 0;
      }
    }
  }

  &:before,
  &:after {
    z-index: $z-index-underground;

    position: absolute;
    top: -3rem;
    right: 0;

    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      $c-base-black 59.14%,
      rgba(0 0 0 / 20%) 105.49%
    );
    content: '';

    clip-path: polygon(0 0, 100% 36%, 100% 100%, 0% 100%);
    @include gt-phone {
      height: 100vh;
    }
  }

  &[data-remove-mask='true'] {
    &:before,
    &:after {
      display: none;
    }
  }

  &:after {
    clip-path: polygon(0 36%, 100% 0, 100% 100%, 0% 100%);
  }

  @include gt-tablet {
    align-items: flex-start;

    width: 48rem;
    height: 100%;

    margin: 0;
    padding: 12rem 0 0;

    &:before,
    &:after {
      z-index: $z-index-underground;

      position: absolute;
      top: 0;
      right: 0;

      width: 250%;
      height: 100%;

      // background: linear-gradient(98.99deg, $c-base-black -12.74%, rgba(0, 0, 0, 0.45) 105.49%);
      background: linear-gradient(
        98.99deg,
        $c-base-black -12.74%,
        rgba(0, 0, 0, 0.3) 105.49%
      );
      content: '';
      clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
    }

    &:after {
      clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
    }
  }
}

.info-container--list {
  @include gt-phone {
    padding: 10.6rem 2.4rem 6rem;
  }

  @include gt-tablet {
    justify-content: center;

    padding: 0;
    padding-left: 2.4rem;
  }
}

.info-container__title {
  max-width: 20rem;
  margin-bottom: $s-s;
  font-weight: 500;

  font-size: $text-size-xlarge;
  line-height: $text-line-height-xlarge;
  text-transform: uppercase;

  @include phone {
    font-size: $text-size-large;
    line-height: $text-line-height-large;
  }
}

.info-container__description {
  max-width: 25rem;
  margin-bottom: $s-s;

  font-size: $text-size-tiny;
  line-height: $text-line-height-default;

  @include gt-phone {
    margin-bottom: $s-xxl;
  }
}

/* ANCHOR LINK
========================================================================== */
.anchor-link {
  margin-top: $s-s;
  &:hover,
  &:focus {
    color: $c-gray-4;
  }
}

/* VARIATIONS
========================================================================== */
[data-title-only='true'] {
  .content {
    @include phone {
      flex-direction: row;
      align-items: flex-end;

      min-height: $banner-height--medium;

      &:before {
        z-index: $z-index-underground;
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: $c-base-black;
        content: '';
        opacity: 0.55;

        pointer-events: none;
      }
    }
  }

  .poster-wrapper__poster {
    @include phone {
      position: absolute;
      top: 0;
      width: 100%;

      height: 100%;
      object-fit: cover;

      pointer-events: none;
    }
  }

  .info-container {
    margin: 0;
    padding-bottom: $banner-title-bottom-spacing;

    @include phone {
      &:before,
      &:after {
        content: none;
      }
    }

    @include gt-phone {
      justify-content: flex-end;

      width: 70rem;
    }
  }

  .info-container__title {
    @include phone {
      max-width: initial;
      margin-bottom: 0;
      font-size: 4rem;
    }
  }
}
