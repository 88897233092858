@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$border-height: 2px;
$border-height--desk: 4px;
$border-height--tiny: 1px;

/* DIVIDER
========================================================================== */
.atom__content-divider-container {
  border: none;
  border-top: $border-height solid $c-gray-3;

  @include gt-phone {
    border-top-width: $border-height--desk;
  }

  &[data-size="tiny"]  {
      border-top-width: $border-height--tiny;
  }

  &[data-size="small"]  {    
    border-top-width: $border-height;    
  }
  
}
