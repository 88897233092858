@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$map-mobile-height: 30rem;
$map-desktop-height: 87.7rem;
$info-max-width: 57.6rem;
$info-position-left: 9.6rem;
$info-position-top: $s-xxl;
$info-padding-desktop: 8rem;
$info-padding-mobile: 3.2rem;
$arrow-position-left-right: 4.2rem;
$arrow-position-top: 11.6rem;

/* MAP INFORMATION CONTAINER
========================================================================== */
.molecule__map-information-container {
  position: relative;

  /* CONTAINER GOOGLE MAP
  ========================================================================== */
  .container-google {
    display: flex;
    position: relative;
    width: 100%;
    height: $map-mobile-height;

    @include gt-phone {
      height: $map-desktop-height;
    }
  }

  /* CONTAINER INFO
  ========================================================================== */
  .carousel-info {
    position: relative;
    width: 100vw;
    max-width: $info-max-width;

    @include gt-phone {
      position: absolute;
      top: $info-position-top;
      left: $info-position-left;
      width: 100%;
    }
  }

  .info {
    padding: $s-lg $info-padding-mobile;
    @include gt-phone {
      padding: $s-lg $info-padding-desktop;
      margin-left: 0;
    }
  }

  /* ARROWS
========================================================================== */
  [class^='slick-arrow'] {
    z-index: $z-index-jump;
    position: absolute;
    top: $arrow-position-top;
    width: 1rem;
    height: 1rem;
    transform: rotate(45deg);
    border: none;
    color: transparent;
    @include gt-phone {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  [class*='slick-prev'] {
    left: $s-s;
    border-bottom: solid 0.15rem $c-primary;
    border-left: solid 0.15rem $c-primary;

    transition: border-left ease $basic-transition;
    transition: border-bottom ease $basic-transition;

    &:hover,
    &:focus {
      border-bottom: solid 0.15rem $c-primary-dark;
      border-left: solid 0.15rem $c-primary-dark;
      transition: border-left ease $basic-transition;
      transition: border-bottom ease $basic-transition;
    }

    @include gt-phone {
      left: $arrow-position-left-right;
    }
  }

  [class*='slick-next'] {
    right: $s-s;
    border-top: solid 0.15rem $c-primary;
    border-right: solid 0.15rem $c-primary;

    transition: border-right ease $basic-transition;
    transition: border-top ease $basic-transition;

    &:hover,
    &:focus {
      border-top: solid 0.15rem $c-primary-dark;
      border-right: solid 0.15rem $c-primary-dark;
      transition: border-right ease $basic-transition;
      transition: border-top ease $basic-transition;
    }

    @include gt-phone {
      right: $arrow-position-left-right;
    }
  }

  [class*='slick-disabled'] {
    cursor: default;
    opacity: 0.2;
  }
}
